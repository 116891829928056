import * as React from 'react';
import { Card, TableRow, TableContainer, TableCell, TableBody, Table } from '@mui/material';

function createRow(name, value) {
  return { name, value };
}

const rows = [
  createRow('Number of Fabrics', 2),
  createRow('Number of Query Workers', 80),
  createRow('Number of Stream Workers', 0),
  createRow('Number of Active Stream Workers', 0),
  createRow('Number of Collections', 42),
  createRow('Number of Spot Collections', 0),
  createRow('Number of Edge Collections', 0),
  createRow('Number of Local Streams', 0),
  createRow('Number of Global Streams', 19),
];

export default function BasicTable() {
  return (
    <TableContainer component={Card} sx={{ maxWidth: 400 }}> 
      <Table  size="small" aria-label="a dense table">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="left"><strong>{row.name}</strong></TableCell>
              <TableCell align="left">{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
