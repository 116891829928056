import { TableContainer, Table, TableBody, TableRow, TableCell, Card } from '@mui/material';

// function createRow(name, id, paymetId, subId, subCollectionMethod, subStatus, subCreated, subPeriodStart, subPeriodEnd, paymentBrand, paymentLastFour, paymentExpireYear, paymentExpireMonth) {
//   return { name, id, paymetId, subId, subCollectionMethod, subStatus, subCreated, subPeriodStart, subPeriodEnd, paymentBrand, paymentLastFour, paymentExpireYear, paymentExpireMonth};
// }

function createRow(name, value) {
  return { name, value };
}

const date = new Date().toISOString();

const rows = [
  createRow('Name', 'Adriaan Van Swieten'),
  createRow('ID', 'cus_Ld835NK0SJihRv'),
  createRow('Payment ID', 'pm_1KvrngBjXsTXkjv3jIMcyYpi'),
  createRow('Subscription ID', 'sub_1MHrDmBjXsTXkjv3QlzUacRM'),
  createRow('Subscription Collection Method', 'charge_automatically'),
  createRow('Subscription Created', date),
  createRow('Subscription Period Start', date),
  createRow('Subscription End', date),
  createRow('Payment Brand', 'visa'),
  createRow('Payment Last Four', '1234'),
  createRow('Payment Expire (Year)', '2022'),
  createRow('Payment Expire (Month)', '3'),
];

export default function AppZendeskInfo() {
  return (
    <TableContainer component={Card} sx={{ maxWidth: 550 }}>
      <Table size="small" aria-label="a dense table">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="left">
                <strong>{row.name}</strong>
              </TableCell>
              <TableCell align="left">{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
