import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useCookies } from 'react-cookie';
import Cookies from 'js-cookie';
import { signinUser } from '../../../apiRoutes/signin';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [signinData, setSigninData] = useState({
    username: '',
    password: '',
  });
  const [error, setError] = useState(false);

  const handleClick = async () => {
    const res = await signinUser(signinData);

    if (res.status === 200) {
      Cookies.set('token', res.data.token, { expires: 1, path: '/dashboard' });
      navigate('/dashboard/app');
    } else {
      setError(true);
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          required
          name="email"
          label="Email address"
          onChange={(e) => setSigninData({ ...signinData, username: e.target.value })}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          required
          onChange={(e) => setSigninData({ ...signinData, password: e.target.value })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Stack mb={2} mt={2}>
        {error && (
          <Alert severity="error">
            {' '}
            Invalid <strong>username</strong> or <strong>password</strong>{' '}
          </Alert>
        )}
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Sign In
      </LoadingButton>
    </>
  );
}
