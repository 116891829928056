import { useState } from 'react';
import Highlight from 'react-highlight';
import PropTypes from 'prop-types';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function createData(name, type, fabric, user) {
  return {
    name,
    type,
    fabric,
    user,
    history: [],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="center">{row.type}</TableCell>
        <TableCell align="center">{row.fabric}</TableCell>
        <TableCell align="center">{row.user}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <Highlight className="sql">{`For data in Pricing
    FILTER data.Name=="Container"
    return Attributes(data.Pricing.Compute,true,true)`}</Highlight>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.number.isRequired,
    fabric: PropTypes.number.isRequired,
    user: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

const rows = [
  createData('test_pols', 'c8ql', 'production', 'root'),
  createData('test2', 'c8ql', 'production', 'root'),
  createData('donations_test', 'c8ql', 'production', 'root'),
  createData('donations2', 'c8ql', 'production', 'root'),
  createData('Taxpayertest', 'c8ql', 'production', 'root'),
];

export default function AppQueryWorker() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              <strong>Name</strong>
            </TableCell>
            <TableCell align="center">
              <strong>Type</strong>
            </TableCell>
            <TableCell align="center">
              <strong>Fabric</strong>
            </TableCell>
            <TableCell align="center">
              <strong>User</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
