import axios from 'axios';
import Cookies from 'js-cookie'

const config = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Authorization': `token=${Cookies.get('token')}`,
  },
};

export const getRoute = async (url) => axios.get(url, config);

export const postRoute = async (url, data) => axios.post(url, data, config);
