import { Helmet } from 'react-helmet-async';
import { Container, Typography, Grid } from '@mui/material';
import {
  AppIssueTypeCount,
  AppLatestTicket,
  AppTicketStatusCount,
  AppZendeskInfo,
  AppZendeskLatestTickets,
} from '../sections/zendesk';

const ZendeskPage = () => {
  return (
    <>
      <Helmet>
        <title> Zendesk | Customer360 </title>
      </Helmet>
      <Container>
        <Typography variant="h3" ml={2} mb={3}>
          Zendesk
        </Typography>
        <Typography variant="h5" mb={3} ml={2}>
          Zendesk Info
        </Typography>
        <AppZendeskInfo />
        <Typography variant="h5" mb={3} ml={2} mt={3}>
          Zendesk Statistics
        </Typography>
        <AppLatestTicket />
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} md={6} lg={6}>
            <Typography ml={2} mb variant="h6">
              Tickets Type Count
            </Typography>
            <AppIssueTypeCount />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography ml={2} mb variant="h6">
              Tickets Status Count
            </Typography>
            <AppTicketStatusCount />
          </Grid>
        </Grid>
        <Typography variant="h5" mb={3} ml={2} mt={3}>
          Zendesk Tickets
        </Typography>
        <AppZendeskLatestTickets />
      </Container>
    </>
  );
};

export default ZendeskPage;
