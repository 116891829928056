import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/system';
import { Typography } from '@mui/material';
import { AppQueryWorker, AppTenantStatistic, AppStreamWorker } from '../sections/collections';

const CollectionsWorkersPage = () => {
  return (
    <>
      <Helmet>
        <title> Collections | Customer360 </title>
      </Helmet>
      <Container>
        <Typography variant="h3" mb={4} ml={2}>
          Collections And Workers
        </Typography>
        <Typography variant="h5" ml={2} mb={2}>
          Tenant Data
        </Typography>
        <AppTenantStatistic />
        <Typography variant="h5" ml={2} mb={2} mt={2}>
          Query Workers
        </Typography>
        <AppQueryWorker />
        <Typography variant="h5" ml={2} mb={2} mt={2}>
          Stream Workers
        </Typography>
        <AppStreamWorker />
      </Container>
    </>
  );
};

export default CollectionsWorkersPage;
