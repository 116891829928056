import { Table, TableBody, TableCell, TableContainer, TableRow, Card, TableHead } from '@mui/material';

function createData(total, startDate, endDate) {
  return { total, startDate, endDate };
}

const date = new Date().toISOString();

const rows = [createData(`144$`, date, date)];

// TODO: To be declared what data should be here, then updated acordingly

export default function AppApiKeys() {
  return (
    <TableContainer component={Card} sx={{ maxWidth: 650 }}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Total</TableCell>
            <TableCell align="left">Start Date</TableCell>
            <TableCell align="left">End Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="left">{row.total}</TableCell>
              <TableCell align="left">{row.startDate}</TableCell>
              <TableCell align="left">{row.endDate}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
