import { TableContainer, Table, TableBody, TableRow, TableCell, Card, TableHead } from '@mui/material';

function createRow(status, count) {
  return { status, count };
}

const rows = [
  createRow('Closed', 28),
  createRow('Deleted', 3),
  createRow('Hold', 5),
  createRow('Solved', 5),
];

export default function AppZendeskInfo() {
  return (
    <TableContainer component={Card} sx={{ maxWidth: 500 }}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.status} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="left">
                <strong>{row.status}</strong>
              </TableCell>
              <TableCell align="left">{row.count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
