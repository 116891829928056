import { Helmet } from 'react-helmet-async';
import { Container, Typography } from '@mui/material';
import { AppSendgridHistory } from '../sections/sendgrid';

const SendgridPage = () => {
  return (
    <>
      <Helmet>
        <title> Sendgrid | Customer360 </title>
      </Helmet>
      <Container>
        <Typography variant="h3" ml={2} mb={2}>
          Sendgrid History
        </Typography>
        <AppSendgridHistory />
      </Container>
    </>
  );
};

export default SendgridPage;
