// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_dashboard'),
  },
  {
    title: 'tenant info and usage',
    path: '/dashboard/tenant',
    icon: icon('ic_user'),
  },
  {
    title: 'collections and workers',
    path: '/dashboard/collections',
    icon: icon('ic_analytics'),
  },
  {
    title: 'features',
    path: '/dashboard/features',
    icon: icon('ic_features'),
  },
  {
    title: 'users and API keys',
    path: '/dashboard/users-data',
    icon: icon('ic_users'),
  },
  {
    title: 'limits',
    path: '/dashboard/limits',
    icon: icon('ic_cart'),
  },
  {
    title: 'zendesk',
    path: '/dashboard/zendesk',
    icon: icon('ic_zendesk'),
  },
  {
    title: 'stripe',
    path: '/dashboard/stripe',
    icon: icon('ic_stripe'),
  },
  {
    title: 'sendgrid',
    path: '/dashboard/sendgrid',
    icon: icon('ic_sendgrid'),
  },
  {
    title: 'hubspot',
    path: '/dashboard/hubspot',
    icon: icon('ic_hubspot'),
  },
];

export default navConfig;
