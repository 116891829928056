import { Container, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { AppInvoicesHistory, AppStripeInfo, AppUpcomingInvoice } from '../sections/stripe';

const StripePage = () => {
  return (
    <>
      <Helmet>
        <title> Stripe | Customer360 </title>
      </Helmet>
      <Container>
        <Typography variant="h3" ml={2} mb={3}>
          Stripe
        </Typography>
        <Typography variant="h5" ml={2} mb={2}>
          Info
        </Typography>
        <AppStripeInfo />
        <Typography variant="h5" ml={2} mb={2} mt={2}>
          Upcoming Invoices
        </Typography>
        <AppUpcomingInvoice />
        <Typography variant="h5" ml={2} mb={2} mt={2}>
          Invoices History
        </Typography>
        <AppInvoicesHistory />
      </Container>
    </>
  );
};

export default StripePage;
