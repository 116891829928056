import { useState } from 'react';
import Highlight from 'react-highlight';
import PropTypes from 'prop-types';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function createData(subject, createdAt, sentFrom, sentTo) {
  return {
    subject,
    createdAt,
    sentFrom,
    sentTo,
    history: [],
  };
}

const date = new Date().toISOString();

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.subject}
        </TableCell>
        <TableCell align="center">{row.createdAt}</TableCell>
        <TableCell align="center">{row.sentFrom}</TableCell>
        <TableCell align="center">{row.sentTo}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="chat">
                <TableBody>
                  <Typography
                    paragraph
                  >{`Hi Adriaan, Everyone is talking about the edge, but what is it really? Check out this blog based on the Screaming in the Cloud podcast "Computing on the Edge with Macrometa's Chetan Venkatesh" to uncover how the edge is a ���new kind of distributed cloud.��� Learn how developers can build apps in a distributed way with Macrometa���s Global Data Network (GDN) that you ���just can���t do in the cloud anymore��� with edgy insights from Chetan Venkatesh, CEO and Co-founder at Macrometa and Corey Quinn, Chief Cloud Economist at The Duckbill Group. Read this blog for highlights from the podcast and to learn more about the edge and Macrometa including: The components of the Macrometa GDN - Global Data Mesh, Edge Compute, and Data Protection How to plug and play with APIs on the edge - with existing applications or choose edge-native The GDN's 175+ points of presence (PoP)s and our service providers How Macrometa is working on helping developers lower their carbon footprint Please feel free to reach out to me if you have any questions. Don't forget you can create a free developer account to try out Macrometa, or join our new Slack Community. Happy New Year! Best Regards, Justin Sr. Director of Product and Developer Relations Macrometa -- Is this email not relevant to you? Click here`}</Typography>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    subject: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    sentFrom: PropTypes.string.isRequired,
    sentTo: PropTypes.string.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

const rows = [
  createData('Computing Edge - Cloud Podcast', date, 'mail@send.com', 'send@mail.com'),
  createData('Computing Edge - Cloud Podcast', date, 'mail@send.com', 'send@mail.com'),
  createData('Computing Edge - Cloud Podcast', date, 'mail@send.com', 'send@mail.com'),
  createData('Computing Edge - Cloud Podcast', date, 'mail@send.com', 'send@mail.com'),
  createData('Computing Edge - Cloud Podcast', date, 'mail@send.com', 'send@mail.com'),
];

export default function AppQueryWorker() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              <strong>Subject</strong>
            </TableCell>
            <TableCell align="center">
              <strong>Created</strong>
            </TableCell>
            <TableCell align="center">
              <strong>From</strong>
            </TableCell>
            <TableCell align="center">
              <strong>To</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.subject} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
