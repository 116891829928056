import { TableContainer, Table, TableBody, TableRow, TableCell, Card } from '@mui/material';

function createRow(name, value) {
  return { name, value };
}

const date = new Date().toISOString();

const rows = [
  createRow('Email', 'evanpredavec@gmail.com'),
  createRow('User ID', 419153660771),
  createRow('Full Name', 'Evan Predavec'),
  createRow('Creation Date', date),
  createRow('Number of Tickets', 60),
  createRow('Organization', ''),
];

export default function AppZendeskInfo() {
  return (
      <TableContainer component={Card} sx={{ maxWidth: 500 }}>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="left">
                  <strong>{row.name}</strong>
                </TableCell>
                <TableCell align="left">{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  );
}
