import { useState } from 'react';
import Highlight from 'react-highlight';
import PropTypes from 'prop-types';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function createData(name, fabric, user, isActive, regions) {
  return {
    name,
    fabric,
    user,
    isActive,
    regions,
    history: [],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="center">{row.fabric}</TableCell>
        <TableCell align="center">{row.user}</TableCell>
        <TableCell align="center">{row.isActive}</TableCell>
        <TableCell align="center">{row.regions}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <Highlight className="sql">{`@App:name("UpdateBestseller")
@App:description("Updates BestsellerTable when a new order comes in the OrdersTable")

define function getBookQuantity[javascript] return int {
    const prevQuantity = arguments[0];
    const nextQuantity = arguments[1];
    
    let newQuantity = nextQuantity;
    if(prevQuantity){
        newQuantity = prevQuantity + nextQuantity;
    }
    return newQuantity;
};

@source(type='c8db', collection='OrdersTable', @map(type='passThrough'))
define stream OrdersTable (_json string);

@sink(type='c8streams', stream='BestsellerIntermediateStream', @map(type='json'))
define stream BestsellerIntermediateStream (bookId string, quantity int);

@store(type = 'c8db', collection='BestsellersTable')
define table BestsellersTable (_key string, quantity int);

select json:getString(jsonElement, '$.bookId') as bookId, json:getInt(jsonElement, '$.quantity') as quantity
from OrdersTable#json:tokenizeAsObject(_json, "$.books[*]")
insert into BestsellerIntermediateStream;

select next.bookId as _key, getBookQuantity(prev.quantity, next.quantity) as quantity
from BestsellerIntermediateStream as next
left outer join BestsellersTable as prev
on next.bookId == prev._key
update or insert into BestsellersTable
set BestsellersTable.quantity = quantity, BestsellersTable._key = _key
on BestsellersTable._key == _key;`}</Highlight>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    fabric: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    regions: PropTypes.string.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

const rows = [
  createData(
    'test_pols',
    'production',
    'root',
    'True',
    'play-ap-southeast, play-ap-southwest, play-ap-southeast, play-ap-southwes, play-ap-southeast, play-ap-southwes, play-ap-southeast, play-ap-southwes,play-ap-southeast, play-ap-southwes,play-ap-southeast'
  ),
  createData(
    'test2',
    'production',
    'root',
    'True',
    'play-ap-southeast, play-ap-southwest, play-ap-southeast, play-ap-southwes, play-ap-southeast, play-ap-southwes, play-ap-southeast, play-ap-southwes,play-ap-southeast, play-ap-southwes,play-ap-southeast'
  ),
  createData(
    'donations_test',
    'production',
    'root',
    'True',
    'play-ap-southeast, play-ap-southwest, play-ap-southeast, play-ap-southwes, play-ap-southeast, play-ap-southwes, play-ap-southeast, play-ap-southwes,play-ap-southeast, play-ap-southwes,play-ap-southeast'
  ),
  createData(
    'donations2',
    'production',
    'root',
    'True',
    'play-ap-southeast, play-ap-southwest, play-ap-southeast, play-ap-southwes, play-ap-southeast, play-ap-southwes, play-ap-southeast, play-ap-southwes,play-ap-southeast, play-ap-southwes,play-ap-southeast'
  ),
  createData(
    'Taxpayertest',
    'production',
    'root',
    'True',
    'play-ap-southeast, play-ap-southwest, play-ap-southeast, play-ap-southwes, play-ap-southeast, play-ap-southwes, play-ap-southeast, play-ap-southwes,play-ap-southeast, play-ap-southwes,play-ap-southeast'
  ),
];

export default function AppStreamWorker() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              <strong>Name</strong>
            </TableCell>
            <TableCell align="center">
              <strong>Fabric</strong>
            </TableCell>
            <TableCell align="center">
              <strong>User</strong>
            </TableCell>
            <TableCell align="center">
              <strong>Active</strong>
            </TableCell>
            <TableCell align="center">
              <strong>Regions</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
