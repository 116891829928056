import { TableContainer, Table, TableBody, TableRow, TableCell, Card } from '@mui/material';

function createRow(feature, value) {
  return { feature, value };
}

const rows = [
  createRow('Tenant', 'TIJDDKS0Q2790312890'),
  createRow('Federation', 'PLAY'),
  createRow('Display Name', 'jon@doe.com'),
  createRow('Name', 'Jon Doe'),
  createRow('Country', 'US'),
  createRow('Plan', 'ENTERPRISE'),
  createRow('Status', 'active'),
  createRow('Created', '23-01-2023'),
];

export default function AppTenantBasicInfo() {
  return (
    <TableContainer component={Card} sx={{ maxWidth: 500 }}>
      <Table size="small" aria-label="a dense table">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="left">
                <strong>{row.feature}</strong>
              </TableCell>
              <TableCell align="left">{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
