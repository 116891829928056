import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(subject, organizationName, email, tier, status, timestamp) {
  return { subject, organizationName, email, tier, status, timestamp };
}

const rows = [
  createData('Issue with Stream Workers', 'Taplytics', 'test@mail.com', 'ENTERPRISE', 'PEND', '21-01-2023'),
  createData('Issue with Query Workers', 'Baplytics', 'test1@mail.com', 'ENTERPRISE', 'OPEN', '21-01-2023'),
  createData('Issue with Workers', 'Caplytics', 'test2@mail.com', 'ENTERPRISE', 'PEND', '23-01-2023'),
  createData('Issue with Graphs', 'TRaplytics', 'test3@mail.com', 'ENTERPRISE', 'CLOSED', '21-12-2022'),
  createData('Question about SW', 'TMAaplytics', 'tesSt@mail.com', 'ENTERPRISE', 'ON HOLD', '21-01-2023'),
  createData('Issue', 'Tapplytics', 'test4@mail.com', 'ENTERPRISE', 'PEND', '19-01-2023'),
  createData('Billing', 'Taplyyytics', 'test7@mail.com', 'ENTERPRISE', 'PEND', '21-01-2023'),
  createData('Something', 'Taplllssytics', 'test5@mail.com', 'ENTERPRISE', 'OPEN', '20-01-2023'),
  createData('Something with Workers', 'Taplyticccs', 'testT@mail.com', 'ENTERPRISE', 'PEND', '21-01-2023'),
  createData('Issue with API', 'Taplyticssss', 'test6@mail.com', 'ENTERPRISE', 'CLOSED', '21-01-2023'),
  createData('Problem with SDK', 'Taplyyyyytics', 'test8@mail.com', 'ENTERPRISE', 'CLOSED', '11-01-2023'),
].sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1));

export default function LatestTicketsTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table" size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>Subject</TableCell>
            <TableCell>Organization Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Tier</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((row) => (
            <TableRow key={row.subject}>
              <TableCell style={{ width: 200 }} align="left">
                {row.subject}
              </TableCell>
              <TableCell style={{ width: 160 }} align="left">
                {row.organizationName}
              </TableCell>
              <TableCell style={{ width: 160 }} align="left">
                {row.email}
              </TableCell>
              <TableCell style={{ width: 120 }} align="left">
                {row.tier}
              </TableCell>
              <TableCell style={{ width: 100 }} align="left">
                {row.status}
              </TableCell>
              <TableCell style={{ width: 110 }} align="left">
                {row.timestamp}
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 30 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20, { label: 'All', value: -1 }]}
              colSpan={6}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
