import { Table, TableBody, TableCell, TableContainer, TableRow, Card, TableHead } from '@mui/material';

function createData(value, updateDate) {
  return { value, updateDate };
}

const date = new Date().toISOString();

const rows = [createData(1499, date)];

export default function AppApiKeys() {
  return (
    <TableContainer component={Card} sx={{ maxWidth: 650 }}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Last Updated Ticket</TableCell>
            <TableCell align="left">Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="left">{row.value}</TableCell>
              <TableCell align="left">{row.updateDate}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
