import { TableContainer, Table, TableBody, TableRow, TableCell, Card } from '@mui/material';

function createRow(name, value) {
  return { name, value };
}

const date = new Date().toISOString();

const rows = [
  createRow('Name', 'John&Brothers'),
  createRow('Website', 'test.com'),
  createRow('Total Revenue', ''),
  createRow('Lead Status', 'undefined'),
  createRow('Created', date),
  createRow('Last Update', date),
  createRow('Link to Hubspot', `link`),
];

export default function AppContactInfo() {
  return (
    <TableContainer component={Card} sx={{ maxWidth: 500 }}>
      <Table size="small" aria-label="a dense table">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="left">
                <strong>{row.name}</strong>
              </TableCell>
              <TableCell align="left">{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
