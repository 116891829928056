import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/system';
import { Typography } from '@mui/material';
import { AppFeatures } from '../sections/features';

const FeaturesPage = () => {
  return (
    <>
      <Helmet>
        <title> Features | Customer360 </title>
      </Helmet>
      <Container>
        <Typography variant="h3" mb={3} ml={2}>
          Features
        </Typography>
        <AppFeatures />
      </Container>
    </>
  );
};

export default FeaturesPage;
