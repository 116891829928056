// ----------------------------------------------------------------------

const account = {
  displayName: 'Bogdan Dzekic',
  email: 'demo@support.cc',
  photoURL:
    'https://camo.githubusercontent.com/6e8a106ce2998328162328f35b12d68eb9927127abef9ff061155b184c6ebc54/68747470733a2f2f7777772e6d6163726f6d6574612e636f6d2f646f63732f696d672f6d6163726f6d6574612d69636f6e2d707572706c652e706e67',
};

export default account;
