import { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Card,
  IconButton,
} from '@mui/material';

function createRow(name, amountDue, amountPaid, amountRemaining, subCreated, paidStatus, subPeriodStart, subPeriodEnd) {
  return { name, amountDue, amountPaid, amountRemaining, subCreated, paidStatus, subPeriodStart, subPeriodEnd };
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const date = new Date().toISOString();

const rows = [
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
  createRow('Adriaan Van Swieten', 892, 892, 0, date, 'true', date, date),
].sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1));

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function AppZendeskInfo() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Card} sx={{ minWidth: 650 }}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table" size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>Customer Name</TableCell>
            <TableCell>Amount Due</TableCell>
            <TableCell>Amount Paid</TableCell>
            <TableCell>Amount Remaining</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Paid</TableCell>
            <TableCell>Period Start</TableCell>
            <TableCell>Period End</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((row) => (
            <TableRow key={row.name}>
              <TableCell align="left">
                <strong>{row.name}</strong>
              </TableCell>
              <TableCell align="left">{row.amountDue}</TableCell>
              <TableCell align="left">{row.amountPaid}</TableCell>
              <TableCell align="left">{row.amountRemaining}</TableCell>
              <TableCell align="left">{row.subCreated}</TableCell>
              <TableCell align="left">{row.paidStatus}</TableCell>
              <TableCell align="left">{row.subPeriodStart}</TableCell>
              <TableCell align="left">{row.subPeriodEnd}</TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 30 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20, { label: 'All', value: -1 }]}
              colSpan={12}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

// export default function LatestTicketsTable() {

//   return (
//     <TableContainer component={Paper}>
//       <Table sx={{ minWidth: 500 }} aria-label="custom pagination table" size={'small'}>
//         <TableHead>
//           <TableRow>
//             <TableCell>Ticket Number</TableCell>
//             <TableCell>Status</TableCell>
//             <TableCell>Subject</TableCell>
//             <TableCell>Last Update</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((row) => (
//             <TableRow key={row.ticketNumber}>
//               <TableCell style={{ width: 70 }} align="left">
//                 {row.ticketNumber}
//               </TableCell>
//               <TableCell style={{ width: 300 }} align="left">
//                 {row.subject}
//               </TableCell>
//               <TableCell style={{ width: 100 }} align="left">
//                 {row.status}
//               </TableCell>
//               <TableCell style={{ width: 200 }} align="left">
//                 {row.timestamp}
//               </TableCell>
//             </TableRow>
//           ))}
//           {emptyRows > 0 && (
//             <TableRow style={{ height: 30 * emptyRows }}>
//               <TableCell colSpan={6} />
//             </TableRow>
//           )}
//         </TableBody>

//       </Table>
//     </TableContainer>
//   );
// }
