import React from 'react';
import {
  Table,
  Stack,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Card,
  TableBody,
  Button,
} from '@mui/material';

function createData(weekNo, apiCalls, diskStorageInMB, indexStorageInMB, queryTimeInSec, steramIOInMB) {
  return { weekNo, apiCalls, diskStorageInMB, indexStorageInMB, queryTimeInSec, steramIOInMB };
}

const data = [
  createData('1', 123, 11, 12, 13, 14),
  createData('2', 343, 23, 22, 21, 20),
  createData('3', 43, 23, 222, 21, 20),
  createData('4', 33, 23, 22, 211, 20),
];

const AppUsageTable = () => {
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3} mb={2} ml={2}>
        <Typography variant="h6">Usage Table</Typography>
        <Button variant="contained">Download CSV</Button> {/* TODO: add functionality to button */}
      </Stack>

      <TableContainer component={Card}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Week No.</TableCell>
              <TableCell align="center">API Calls</TableCell>
              <TableCell align="center">Disk Storage(MB)</TableCell>
              <TableCell align="center">Index Storage(MB)</TableCell>
              <TableCell align="center">Query Time(s)</TableCell>
              <TableCell align="center">StreamIO(MB)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((data) => (
              <TableRow key={data.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="left">{data.weekNo}</TableCell>
                <TableCell align="center">{data.apiCalls}</TableCell>
                <TableCell align="center">{data.diskStorageInMB}</TableCell>
                <TableCell align="center">{data.indexStorageInMB}</TableCell>
                <TableCell align="center">{data.queryTimeInSec}</TableCell>
                <TableCell align="center">{data.steramIOInMB}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AppUsageTable;
