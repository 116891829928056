import { Helmet } from 'react-helmet-async';
import { Typography } from '@mui/material';
import { Container } from '@mui/system';
import { AppApiKeys } from '../sections/users';

const UsersDataPage = () => {
  return (
    <>
      <Helmet>
        <title> Users | Customer360 </title>
      </Helmet>
      <Container>
        <Typography variant="h3" mb={3} ml={2}>
          Users And API Keys
        </Typography>
        <AppApiKeys />
      </Container>
    </>
  );
};

export default UsersDataPage;
