import { Table, TableBody, TableCell, TableContainer, TableRow, Card } from '@mui/material';

function createData(name, status) {
  return { name, status };
}
/* TODO: Add option to place checkmark instead of True/False */

const rows = [
  createData('CEP', 'True'),
  createData('COMPUTE', 'False'),
  createData('COMPUTE AKAMAI', 'True'),
  createData('COMPUTE FAAS', 'True'),
  createData('DOCS', 'True'),
  createData('DYNAMO', 'True'),
  createData('EVENT HUB', 'False'),
  createData('GEO FABRICS', 'True'),
  createData('GRAPHS', 'True'),
  createData('KV', 'True'),
  createData('LOCAL COLLECTIONS', 'False'),
  createData('REDIS', 'True'),
  createData('SEARCH', 'True'),
  createData('SQL', 'True'),
  createData('STREAMS', 'True'),
  createData('STREAM IO CONNECTORS', 'False'),
  createData('USERS', 'True'),
];

export default function AppFeatures() {
  return (
    <TableContainer component={Card} sx={{ maxWidth: 400 }}>
      <Table size="small" aria-label="a dense table">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="left">
                <strong>{row.name}</strong>
              </TableCell>
              <TableCell align="left">{row.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
