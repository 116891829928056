import { Table, TableBody, TableCell, TableContainer, TableRow, Card, TableHead } from '@mui/material';

function createData(email, user, parent) {
  return { email, user, parent };
}

const rows = [
  createData('restorecox@apikey', 'restorecox', 'Tl8B1v2xtRh-Z7cL1BUL16A.root'),
  createData('cox@apikey', 'cox', 'Tl8B1v2xtRh-Z7cL1BUL16A.root'),
  createData('edgeprobedata@cox.com', 'root', ''),
];

export default function AppApiKeys() {
  return (
    <TableContainer component={Card} sx={{ maxWidth: 650 }}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">User</TableCell>
            <TableCell align="left">Parent</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="left">{row.email}</TableCell>
              <TableCell align="left">{row.user}</TableCell>
              <TableCell align="left">{row.parent}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
