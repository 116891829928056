import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useCookies } from 'react-cookie';
import { latestTenants } from '../../../apiRoutes/dashboard';

const columns = [
  {
    field: 'id',
    headerAlign: 'center',
    width: 40,
  },
  {
    field: 'Tenant',
    headerAlign: 'center',
    width: 235,
  },
  {
    field: 'DisplayName',
    headerAlign: 'center',
    width: 250,
  },
  {
    field: 'Plan',
    headerAlign: 'center',
    width: 140,
  },
  {
    field: 'Federation',
    headerAlign: 'center',
    width: 90,
  },
  {
    field: 'Email',
    headerAlign: 'center',
    width: 235,
  },
];

const rows = [];

export default function AppLatestTenantList() {
  const [tenantsData, setTenantsData] = useState(null);
  const [cookie] = useCookies();
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    async function getDashboardData() {
      document.cookie = `token=${cookie.token}`;
      const { data } = await latestTenants();

      if (data !== null && data !== tenantsData) {
        setTenantsData(data);
      }
    }

    getDashboardData();
  }, []);

  if (tenantsData === null) {
    return null;
  }

  return (
    <Box sx={{ height: 375, width: '100%' }}>
      <DataGrid
        rows={tenantsData.response.result.map((row, index) => ({ ...row, id: index + 1 }))}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 15]}
        pagination
      />
    </Box>
  );
}
