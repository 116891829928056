import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/system';
import { Typography } from '@mui/material';
import { AppLimits } from '../sections/limits';

const LimitsPage = () => {
  return (
    <>
      <Helmet>
        <title> Limits | Customer360 </title>
      </Helmet>
      <Container>
        <Typography variant="h3" mb={3} ml={2}>
          Limits
        </Typography>
        <AppLimits />
      </Container>
    </>
  );
};

export default LimitsPage;
