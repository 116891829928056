import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Container, Typography } from '@mui/material';
// sections
import { useCookies } from 'react-cookie';
import { metricsUser } from '../apiRoutes/dashboard';
import { AppLatestTenantList, AppWidgetSummary, AppLatestTickets } from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const [metricsData, setMetricsData] = useState(null);
  const [cookie] = useCookies();

  useEffect(() => {
    async function getDashboardData() {
      const { data } = await metricsUser();

      if (data && data !== metricsData) {
        setMetricsData(data);
      }
    }

    getDashboardData();
  }, []);

  if (metricsData === null) {
    return null;
  }

  const newTicketsCount =
    metricsData.response.result[0].ticketNumber &&
    metricsData.response.result[0].ticketNumber[0] &&
    metricsData.response.result[0].ticketNumber[0].ticketNumber !== undefined
      ? metricsData.response.result[0].ticketNumber[0].ticketNumber
      : 0;

  const newPlayUsersCount =
    metricsData.response.result[0].newUsers &&
    metricsData.response.result[0].newUsers[0] &&
    metricsData.response.result[0].newUsers[0].count !== undefined
      ? metricsData.response.result[0].newUsers[0].count
      : 0;

  const newScaleUsersCount =
    metricsData.response.result[0].newUsers &&
    metricsData.response.result[0].newUsers[1] &&
    metricsData.response.result[0].newUsers[1].count !== undefined
      ? metricsData.response.result[0].newUsers[1].count
      : 0;

  const newPlayUsersName =
    metricsData.response.result[0].newUsers &&
    metricsData.response.result[0].newUsers[0] &&
    metricsData.response.result[0].newUsers[0].plan !== undefined
      ? metricsData.response.result[0].newUsers[0].plan
      : 'PLAYGROUND';

  const newScaleUsersName =
    metricsData.response.result[0].newUsers &&
    metricsData.response.result[0].newUsers[1] &&
    metricsData.response.result[0].newUsers[1].plan !== undefined
      ? metricsData.response.result[0].newUsers[1].plan
      : 'SCALE';

  return (
    <>
      <Helmet>
        <title> Dashboard | Customer360 </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h6" sx={{ mb: 3, ml: 2 }}>
          Metrics for past 7 days
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="New Tickets" color="warning" total={newTicketsCount} icon={'mdi:paper'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={`New ${newPlayUsersName} Users`}
              total={newPlayUsersCount}
              color="info"
              icon={'mdi:user'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={`New ${newScaleUsersName} Users`}
              total={newScaleUsersCount}
              color="success"
              icon={'mdi:user'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="SCALE Users Left"
              total={0} // TODO: PULL PROPER VALUE FROM DATA
              color="error"
              icon={'material-symbols:person-remove-rounded'}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mb: 3, ml: 2 }}>
              Latest Tickets
            </Typography>
            <AppLatestTickets />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mb: 3, ml: 2 }}>
              Latest Tenants
            </Typography>
            <AppLatestTenantList />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
