import { TableContainer, Table, TableBody, TableRow, TableCell, Card, TableHead } from '@mui/material';

function createRow(name, value) {
  return { name, value };
}

const rows = [
  createRow('null', 28),
  createRow('Billing Feature', 3),
  createRow('C8QL', 5),
  createRow('Collections', 5),
  createRow('Geofabrics', 2),
  createRow('Other', 1),
  createRow('Query Workers', 8),
  createRow('Search', 6),
  createRow('UI/UX', 2),
];

export default function AppZendeskInfo() {
  return (
    <TableContainer component={Card} sx={{ maxWidth: 500 }}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Issue Type</TableCell>
            <TableCell>Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="left">
                <strong>{row.name}</strong>
              </TableCell>
              <TableCell align="left">{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
