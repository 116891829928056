import { Helmet } from 'react-helmet-async';
import { Container, Grid, Typography } from '@mui/material';
import {
  AppContactInfo,
  AppCompanyInfo,
  AppLatestDeals,
  AppMeetingsHistory,
  AppEmailHistory,
} from '../sections/hubspot';

const HubspotPage = () => {
  return (
    <>
      <Helmet>
        <title> Hubspot | Customer360 </title>
      </Helmet>
      <Container>
        <Typography variant="h3" ml={2} mb={3}>
          Hubspot
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <Typography ml={2} mb variant="h6">
              Contact Information
            </Typography>
            <AppContactInfo />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography ml={2} mb variant="h6">
              Company Information
            </Typography>
            <AppCompanyInfo />
          </Grid>
        </Grid>
        <Typography ml={2} mt={2} mb variant="h5">
          Deals
        </Typography>
        <AppLatestDeals />
        <Typography ml={2} mt={2} mb={2} variant="h5">
          Meetings
        </Typography>
        <AppMeetingsHistory />
        <Typography ml={2} mt={2} mb={2} variant="h5">
          Mails
        </Typography>
        <AppEmailHistory />
      </Container>
    </>
  );
};

export default HubspotPage;
