import { Helmet } from 'react-helmet-async';

// @mui
import { Container, Typography, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  AppCustomerStorageUsage,
  AppQueryExecution,
  AppCustomerAPICalls,
  AppTenantBasicInfo,
  AppUsageTable,
} from '../sections/tenant';

export default function TenantPage() {
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title> Tenant | Customer360 </title>
      </Helmet>

      <Container>
        <Typography variant="h3" mb={4} ml={2}>
          Info And Usage
        </Typography>
        <AppTenantBasicInfo />
        <AppUsageTable />
        <Grid item xs={3} mt={3}>
          <AppCustomerStorageUsage
            title="Customer Storage Usage"
            chartLabels={[
              '01/01/2023',
              '02/01/2023',
              '03/01/2023',
              '04/01/2023',
              '05/01/2023',
              '06/01/2023',
              '07/01/2023',
              '08/01/2023',
              '09/01/2023',
              '10/01/2023',
              '11/01/2023',
            ]}
            chartData={[
              {
                name: 'Disk Storage',
                type: 'area',
                fill: 'gradient',
                data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
              },
              {
                name: 'Index Storage',
                type: 'area',
                fill: 'gradient',
                data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
              },
              {
                name: 'Stream IO',
                type: 'area',
                fill: 'gradient',
                data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
              },
            ]}
          />
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6} mt={3}>
            <AppCustomerAPICalls
              title="API Calls"
              chartLabels={[
                '01/01/2023',
                '02/01/2023',
                '03/01/2023',
                '04/01/2023',
                '05/01/2023',
                '06/01/2023',
                '07/01/2023',
                '08/01/2023',
                '09/01/2023',
                '10/01/2023',
                '11/01/2023',
              ]}
              chartData={[
                {
                  name: 'API Calls',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6} mt={3}>
            <AppQueryExecution
              title="Query Execution"
              chartLabels={[
                '01/01/2023',
                '02/01/2023',
                '03/01/2023',
                '04/01/2023',
                '05/01/2023',
                '06/01/2023',
                '07/01/2023',
                '08/01/2023',
                '09/01/2023',
                '10/01/2023',
                '11/01/2023',
              ]}
              chartColors={[theme.palette.error.main]}
              chartData={[
                {
                  name: 'Query Execution',
                  type: 'column',
                  fill: 'solid',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
