import { Navigate, useRoutes } from 'react-router-dom';
import Cookies from 'js-cookie';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import {
  DashboardAppPage,
  TenantPage,
  CollectionsWorkersPage,
  FeaturesPage,
  LimitsPage,
  SendgridPage,
  StripePage,
  UsersDataPage,
  ZendeskPage,
  SigninPage,
  HubspotPage,
  Page404,
} from './pages';

// ----------------------------------------------------------------------

export default function Router() {
  let allRoutes = [
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'tenant', element: <TenantPage /> },
        { path: 'collections', element: <CollectionsWorkersPage /> },
        { path: 'features', element: <FeaturesPage /> },
        { path: 'users-data', element: <UsersDataPage /> },
        { path: 'limits', element: <LimitsPage /> },
        { path: 'zendesk', element: <ZendeskPage /> },
        { path: 'stripe', element: <StripePage /> },
        { path: 'sendgrid', element: <SendgridPage /> },
        { path: 'hubspot', element: <HubspotPage /> },
      ],
    },
    {
      path: '/sign',
      element: <SigninPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/sign" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ];

  if (!Cookies.get('token')) {
    allRoutes = allRoutes.slice(1);
  }

  return useRoutes(allRoutes);
}
