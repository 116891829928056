import { Table, TableBody, TableCell, TableContainer, TableRow, Card } from '@mui/material';

function createData(name, status) {
  return { name, status };
}
/* TODO: Add valid data */

const rows = [
  createData('CEP', 50),
  createData('COMPUTE', 150),
  createData('COMPUTE AKAMAI', 50),
  createData('COMPUTE FAAS', 50),
  createData('DOCS', 50),
  createData('DYNAMO', 50),
  createData('EVENT HUB', 150),
  createData('GEO FABRICS', 50),
  createData('GRAPHS', 50),
  createData('KV', 50),
  createData('LOCAL COLLECTIONS', 150),
  createData('REDIS', 50),
  createData('SEARCH', 50),
  createData('SQL', 50),
  createData('STREAMS', 50),
  createData('STREAM IO CONNECTORS', 150),
  createData('USERS', 50),
];

export default function AppFeatures() {
  return (
    <TableContainer component={Card} sx={{ maxWidth: 400 }}>
      <Table size="small" aria-label="a dense table">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="left">
                <strong>{row.name}</strong>
              </TableCell>
              <TableCell align="left">{row.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
