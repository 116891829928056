import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
} from '@mui/material';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const date = new Date().toISOString();

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(timestamp, status, clickCount, openCount, subject) {
  return { timestamp, status, clickCount, openCount, subject };
}

const rows = [
  createData(date, 'delivered', 2, 0, 'Issue with Stream Workers'),
  createData(date, 'delivered', 2, 2, 'Issue  Stream Workers'),
  createData(date, 'delivered', 2, 0, 'Issue with  Workers'),
  createData(date, 'delivered', 2, 2, 'Issue with Stream'),
  createData(date, 'delivered', 2, 0, 'Issue with Stream Workers'),
  createData(date, 'delivered', 2, 2, 'Issue  Stream Workers'),
  createData(date, 'delivered', 2, 0, 'Issue with  Workers'),
  createData(date, 'delivered', 2, 2, 'Issue with Stream'),
  createData(date, 'delivered', 2, 0, 'Issue with Stream Workers'),
  createData(date, 'delivered', 2, 2, 'Issue  Stream Workers'),
  createData(date, 'delivered', 2, 0, 'Issue with  Workers'),
  createData(date, 'delivered', 2, 2, 'Issue with Stream'),
  createData(date, 'delivered', 2, 0, 'Issue with Stream Workers'),
  createData(date, 'delivered', 2, 2, 'Issue  Stream Workers'),
  createData(date, 'delivered', 2, 0, 'Issue with  Workers'),
  createData(date, 'delivered', 2, 2, 'Issue with Stream'),
  createData(date, 'delivered', 2, 0, 'Issue with Stream Workers'),
  createData(date, 'delivered', 2, 2, 'Issue  Stream Workers'),
  createData(date, 'delivered', 2, 0, 'Issue with  Workers'),
  createData(date, 'delivered', 2, 2, 'Issue with Stream'),
].sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1));

export default function LatestTicketsTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table" size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>Last Event Time</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Open Count</TableCell>
            <TableCell>Click Count</TableCell>
            <TableCell>Subject</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((row) => (
            <TableRow key={row.timestamp}>
              <TableCell style={{ width: 200 }} align="left">
                {row.timestamp}
              </TableCell>
              <TableCell style={{ width: 100 }} align="left">
                {row.status}
              </TableCell>
              <TableCell style={{ width: 50 }} align="left">
                {row.openCount}
              </TableCell>
              <TableCell style={{ width: 50 }} align="left">
                {row.clickCount}
              </TableCell>
              <TableCell style={{ width: 200 }} align="left">
                {row.subject}
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 30 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 15, 20, { label: 'All', value: -1 }]}
              colSpan={6}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
